import * as React from "react"
import { CgClose, CgSearch } from "react-icons/cg"
import { Box, BoxProps, IconButton, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"

import { useForm } from "lib/hooks/useForm"

import { Form } from "./Form"
import { Input } from "./Input"

interface Props extends BoxProps {
  search: string
  onSearch?: (search: string) => void
  placeholder?: string
  onChangeHandler?: (search: string) => void
}

export function UserNavSearch({ onSearch, search, onChangeHandler, ...props }: Props) {
  const defaultValues = { search: "" }
  const form = useForm({ defaultValues })

  const clearSearch = () => {
    if (onChangeHandler) return onChangeHandler("")
  }

  const pendingSearch = form.watch("search") as string

  return (
    <Box>
      <Form {...form}>
        <InputGroup>
          <InputLeftElement w={10}>
            <IconButton
              type="submit"
              size="sm"
              aria-label="zoeken indienen"
              variant="ghost"
              icon={<Box as={CgSearch} />}
            />
          </InputLeftElement>
          <Input
            name="search"
            value={search}
            px={10}
            placeholder={props.placeholder}
            {...(onChangeHandler && { onChange: (e) => onChangeHandler(e.target.value) })}
            autoComplete="off"
            minW={{ base: 200, xl: 300 }}
            {...props}
            aria-label={props.placeholder}
            role="search"
          />

          <InputRightElement w={10}>
            {(!!pendingSearch || !!search) && (
              <IconButton
                onClick={clearSearch}
                size="sm"
                aria-label="zoekactie verwijderen"
                variant="ghost"
                icon={<Box as={CgClose} />}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </Form>
    </Box>
  )
}
