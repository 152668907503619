import * as React from "react"
import { Box, DarkMode } from "@chakra-ui/react"

import { NAV_HEIGHT } from "lib/config"

import { Footer } from "./Footer"
import { Nav } from "./Nav"
import { WhatsApp } from "./WhatsApp"

interface Props {
  children: React.ReactNode
}

export function Layout(props: Props) {
  return (
    <>
      <DarkMode>
        <Nav />
        <Box pt={NAV_HEIGHT} minH="95vh">
          {props.children}
        </Box>
        <WhatsApp />
        <Footer />
      </DarkMode>
    </>
  )
}
