import * as React from "react"
import { CgClose } from "react-icons/cg"
import { gql } from "@apollo/client"
import { Box, Button, Flex, HStack, IconButton, Stack, Text } from "@chakra-ui/react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useRouter } from "next/router"
import "dayjs/locale/nl"

import {
  GetNotificationsDocument,
  NotificationItemFragment,
  useUpdateNotificationMutation,
} from "lib/graphql"
import { useMutationHandler } from "lib/hooks/useMutationHandler"
dayjs.extend(relativeTime)
dayjs.locale("nl")

const _ = gql`
  fragment NotificationItem on Notification {
    id
    isRead
    type
    message
    buttonUrl
    userId
    user {
      id
      email
      fullName
    }
    commentId
    comment {
      id
      message
      user {
        id
        fullName
        avatarUrl
      }
      post {
        id
        title
      }
      project {
        id
        title
        creatorId
      }
    }
    postId
    post {
      id
      title
      category
      user {
        id
        fullName
        avatarUrl
      }
    }
    eventId
    event {
      id
      name
      slug
      location
      date
    }
    projectId
    project {
      id
      title
      creator {
        id
        firstName
        lastName
      }
    }
    tutorialId
    tutorial {
      id
      title
      instructor
    }
    actId
    act {
      id
      name
      competition {
        id
        name
        deadline
        firstRoundDate
        slug
      }
    }
    createdAt
    updatedAt
  }
`

interface NotificationProps {
  notification: NotificationItemFragment
  onRead: () => void
}

export function NotificationItem({
  notification: { id, type, message, buttonUrl, createdAt, ...notification },
  onRead,
}: NotificationProps) {
  const router = useRouter()
  const handler = useMutationHandler()
  const [readNotification, { loading }] = useUpdateNotificationMutation({})

  const url =
    type === "COMMENT"
      ? notification.comment?.post
        ? `/prikbord/${notification.comment?.post.id}`
        : notification.comment?.project &&
          `/users/${notification.comment?.project.creatorId}/projecten/${notification.comment?.project.id}`
      : type === "EVENT_ADDED" || type === "UPCOMING_EVENT"
      ? `/te-doen/${notification.event?.slug}`
      : type === "POST"
      ? `/prikbord/${notification.postId}`
      : type === "TUTORIAL"
      ? `/tutorials/${notification.tutorialId}`
      : type === "PROJECT_ADDED"
      ? `/users/${notification.project?.creator.id}/projecten/${notification.projectId}`
      : type === "UPCOMING_COMPETITION"
      ? `/wedstrijd/${notification.act?.competition?.slug}`
      : type === "CUSTOM" && buttonUrl
      ? buttonUrl
      : null

  const handleReadNotification = () => {
    onRead()
    return handler(
      () =>
        readNotification({
          variables: { id, data: { isRead: true } },
          refetchQueries: [{ query: GetNotificationsDocument }],
        }),
      {
        onSuccess: (_) => {
          if (url) {
            router.push(url)
          }
        },
      },
    )
  }

  const handleCloseNotification = () => {
    // onRead()
    return handler(() =>
      readNotification({
        variables: { id, data: { isRead: true } },
        refetchQueries: [{ query: GetNotificationsDocument }],
      }),
    )
  }

  switch (type) {
    case "COMMENT":
      const title = notification.comment?.post
        ? notification.comment?.post.title
        : notification.comment?.project
        ? notification.comment?.project.title
        : ""
      return (
        <Flex>
          <Stack w="100%" position="relative">
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                {notification.comment?.user.fullName} heeft zojuist gereageerd{" "}
                <Text as="span" fontWeight="bold">
                  "{notification.comment?.message}"
                </Text>{" "}
                op het {notification.comment?.post ? "bericht" : "project"}{" "}
                <Text as="span" fontWeight="bold">
                  {title}
                </Text>
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Lees de reactie
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "POST":
      return (
        <Flex>
          <Stack w="100%" position="relative">
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                {notification.post?.user.fullName} heeft zojuist een nieuw prikbord bericht geplaatst, met als
                titel:{" "}
                <Text as="span" fontWeight="bold">
                  "{notification.post?.title}"
                </Text>
                . In de categorie{" "}
                <Text as="span" fontWeight="bold">
                  {notification.post?.category}
                </Text>
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk prikbord bericht
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "TUTORIAL":
      return (
        <Flex>
          <Stack w="100%" position="relative">
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                Een nieuwe tutorial{" "}
                <Text as="span" fontWeight="bold">
                  "{notification.tutorial?.title}"
                </Text>{" "}
                van{" "}
                <Text as="span" fontWeight="bold">
                  {notification.tutorial?.instructor}
                </Text>{" "}
                is nu te bekijken!
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk tutorial
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "EVENT_ADDED":
      return (
        <Flex>
          <Stack w="100%" position="relative" spacing={0}>
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                Er is een nieuw te doen{" "}
                <Text as="span" fontWeight="bold">
                  "{notification.event?.name}"
                </Text>{" "}
                in {notification.event?.location} toegevoegd aan de agenda. Wellicht is het iets voor jou?
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk te doen
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "PROJECT_ADDED":
      return (
        <Flex>
          <Stack w="100%" position="relative" spacing={0}>
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                Er is een nieuw project{" "}
                <Text as="span" fontWeight="bold">
                  "{notification.project?.title}"
                </Text>{" "}
                van {notification.project?.creator.firstName} {notification.project?.creator.lastName}
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk project
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "UPCOMING_EVENT":
      return (
        <Flex>
          <Stack w="100%" position="relative" spacing={0}>
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                Reminder: Een te doen waarvoor jij hebt in te schrijven vindt plaats over{" "}
                {dayjs(notification.event?.date).fromNow()}:
                <Text as="span" fontWeight="bold">
                  "{notification.event?.name}"
                </Text>{" "}
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk te doen
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "UPCOMING_COMPETITION":
      return (
        <Flex>
          <Stack w="100%" position="relative">
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">
                Reminder: Jouw act {notification.act?.name} is bijna aan de beurt bij de voorronde bij jou in
                de buurt {dayjs(notification.act?.competition?.firstRoundDate).fromNow()}:
                <Text as="span" fontWeight="bold">
                  "{notification.act?.competition.name}"
                </Text>{" "}
              </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                Bekijk voorronde
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    case "CUSTOM":
      return (
        <Flex>
          <Stack w="100%" position="relative">
            <IconButton
              position="absolute"
              top={-2}
              right={0}
              onClick={handleCloseNotification}
              size="sm"
              aria-label="notificatie verwijderen"
              variant="ghost"
              icon={<Box as={CgClose} />}
            />
            <Box pr={8}>
              <Text fontSize="sm">{message}</Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(createdAt).fromNow()}
              </Text>
            </Box>
            <HStack justify="flex-end">
              <Button
                size="sm"
                colorScheme="yellow"
                onClick={handleReadNotification}
                isLoading={loading}
                isDisabled={loading}
              >
                {url ? "Bekijk" : "Ok"}
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )
    default:
      return null
  }
}
