import * as React from "react"
import { Image, Link } from "@chakra-ui/react"

export const WhatsApp = () => {
  return (
    <Link
      position="fixed"
      bottom={6}
      right={6}
      zIndex={150}
      isExternal
      href="https://api.whatsapp.com/send?phone=31612076073&text=&source=&data="
      aria-label="opent whatsapp (wordt geopend in een nieuw tabblad)"
    >
      <Image h="50px" w="50px" src="/whatsapp.svg" alt="Whatsapp logo" />
    </Link>
  )
}
