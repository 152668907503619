import * as React from "react"
import { FaSearch, FaUser } from "react-icons/fa"
import { GiHamburgerMenu } from "react-icons/gi"
import {
  Avatar,
  Box,
  Button,
  Center,
  DarkMode,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import NextLink from "next/link"

import { NAV_HEIGHT } from "lib/config"
import { Role } from "lib/graphql"
import { transformImage } from "lib/helpers/utils"
import { useLogout } from "lib/hooks/useLogout"
import { useMe } from "lib/hooks/useMe"

import { Limiter } from "./Limiter"
import { Notifications } from "./Notifications"
import { UsersSearch } from "./UsersSearch"

export function Nav() {
  const { me, loading } = useMe()
  const logout = useLogout()

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Flex
      transition="200ms all"
      justify="space-between"
      align="center"
      bg="black"
      position="fixed"
      top={0}
      zIndex={1000}
      w="100%"
      h={NAV_HEIGHT}
    >
      <Limiter>
        <Flex align="center" justify="space-between">
          <HStack color="white">
            <Link as={NextLink} href="/">
              <Center cursor="pointer" mr={6}>
                <Logo />
              </Center>
            </Link>

            {!isMobile && (
              <HStack spacing={8}>
                <Link
                  as={NextLink}
                  passHref
                  href="/wedstrijd"
                  fontSize="lg"
                  variant="link"
                  color="white"
                  textTransform="uppercase"
                  fontWeight="black"
                >
                  Wedstrijd
                </Link>
                <Link
                  as={NextLink}
                  passHref
                  href="/te-doen"
                  fontSize="lg"
                  color="white"
                  textTransform="uppercase"
                  fontWeight="black"
                >
                  Te doen
                </Link>
                <Link
                  as={NextLink}
                  passHref
                  href="/tutorials"
                  fontSize="lg"
                  variant="link"
                  color="white"
                  textTransform="uppercase"
                  fontWeight="black"
                >
                  Tutorials
                </Link>
                <Link
                  as={NextLink}
                  passHref
                  href="/prikbord"
                  fontSize="lg"
                  variant="link"
                  color="white"
                  textTransform="uppercase"
                  fontWeight="black"
                >
                  Prikbord
                </Link>
              </HStack>
            )}
          </HStack>

          {/* DESKTOP SEARCH */}
          {me && !isMobile && (
            <Box>
              <UsersSearch />
            </Box>
          )}

          <HStack spacing={6}>
            {/* MOBILE SEARCH */}
            {me && isMobile && (
              <Popover placement="bottom-start">
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="zoek members"
                        variant="ghost"
                        icon={<FaSearch />}
                        rounded="full"
                      />
                    </PopoverTrigger>
                    <PopoverContent bg="black" py={2}>
                      <UsersSearch closeMobileMenu={onClose} />
                    </PopoverContent>
                  </>
                )}
              </Popover>
            )}

            {/* NOTIFICATIONS */}
            {me && <Notifications />}

            {/* MENU */}
            {loading
              ? null
              : (isMobile || (!isMobile && me)) && (
                  <Menu>
                    <DarkMode>
                      <MenuButton
                        aria-label="menu"
                        as={IconButton}
                        icon={
                          me ? (
                            <Avatar
                              src={
                                me?.avatarUrl ? transformImage(me?.avatarUrl, "w_45,h_45,g_faces") : undefined
                              }
                              name={me?.fullName || "avatar"}
                              bg="gray.600"
                              icon={<FaUser color="gray.900" size={18} />}
                              boxSize="40px"
                              color="black"
                              fontSize="sm"
                              borderRadius="50%"
                            />
                          ) : (
                            <Flex as={GiHamburgerMenu} color="white" boxSize={30} />
                          )
                        }
                        h="40px"
                        w="40px"
                        minW="40px"
                        borderRadius={me ? "50%" : "sm"}
                      />
                    </DarkMode>
                    <MenuList borderRadius="sm">
                      {isMobile && (
                        <>
                          <NextLink href="/wedstrijd">
                            <MenuItem>Wedstrijd</MenuItem>
                          </NextLink>

                          <NextLink href="/te-doen">
                            <MenuItem>Te doen</MenuItem>
                          </NextLink>

                          <NextLink href="/tutorials">
                            <MenuItem>Tutorials</MenuItem>
                          </NextLink>

                          <NextLink href="/prikbord">
                            <MenuItem>Prikbord</MenuItem>
                          </NextLink>
                        </>
                      )}
                      {isMobile && <MenuDivider />}
                      {me ? (
                        <>
                          <NextLink href={`/users/${me.customSlug ? me.customSlug : me.id}`}>
                            <MenuItem>Profiel</MenuItem>
                          </NextLink>
                          {me.role === Role.Admin && (
                            <NextLink href={`/admin`}>
                              <MenuItem>Admin</MenuItem>
                            </NextLink>
                          )}
                          <MenuItem onClick={logout}>Sign out</MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem>
                            <NextLink href="/login">Login</NextLink>
                          </MenuItem>
                          <MenuItem>
                            <NextLink href="/inschrijven">Account aanmaken</NextLink>
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </Menu>
                )}

            {/* MENU - LOGGED OUT DESKTOP */}
            {!isMobile && !loading && !!!me && (
              <HStack>
                <DarkMode>
                  <Button as={NextLink} passHref href="/login" colorScheme="yellow" variant="ghost">
                    Login
                  </Button>
                </DarkMode>
                <Button as={NextLink} passHref href="/inschrijven" colorScheme="yellow">
                  Account aanmaken
                </Button>
              </HStack>
            )}
          </HStack>
        </Flex>
      </Limiter>
      {me?.hasPendingFeedback && (
        <Flex
          as={Link}
          href={`/users/${me.customSlug ? me.customSlug : me.id}`}
          textDecoration="none"
          w="100%"
          bgColor="yellow.500"
          position="absolute"
          bottom={-10}
          justify="center"
          p={2}
        >
          <Text color="black" fontWeight="bold">
            Je hebt een nieuwe juryscorekaart
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const Logo = () => {
  return <Image h="60px" alt="kunstbende logo" src="/logo.png" />
}
