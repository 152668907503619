import * as React from "react"
import { FaUser } from "react-icons/fa"
import { IoMdNotifications } from "react-icons/io"
import { gql } from "@apollo/client"
import {
  Avatar,
  Box,
  Button,
  Center,
  DarkMode,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import NextLink from "next/link"

import {
  EventPendingReviewFragment,
  GetPendingConnectionsDocument,
  GetUserDocument,
  PendingActFragment,
  PendingConnectionFragment,
  useAcceptConnectionMutation,
  useGetEventsPendingReviewQuery,
  useGetNotificationsQuery,
  useGetPendingActInvitationsQuery,
  useGetPendingConnectionsQuery,
  useRejectConnectionMutation,
} from "lib/graphql"
import { transformImage } from "lib/helpers/utils"
import { useMe } from "lib/hooks/useMe"
import { useMutationHandler } from "lib/hooks/useMutationHandler"

import { LinkButton } from "./LinkButton"
import { NotificationItem } from "./NotificationItem"
dayjs.extend(relativeTime)

const _ = gql`
  query GetNotifications {
    notifications {
      ...NotificationItem
    }
  }

  fragment PendingConnection on UserConnection {
    id
    status
    createdAt
    follower {
      id
      fullName
      avatarUrl
    }
  }

  query GetPendingConnections {
    me {
      id
      pendingConnections {
        ...PendingConnection
      }
    }
  }

  mutation AcceptConnection($followerId: String!) {
    acceptConnection(followerId: $followerId)
  }

  mutation RejectConnection($followerId: String!) {
    rejectConnection(followerId: $followerId)
  }

  fragment PendingAct on ActInvitation {
    id
    actId
    email
    createdAt
  }

  query GetPendingActInvitations {
    pendingActInvitations {
      ...PendingAct
    }
  }

  mutation UpdateNotification($id: String!, $data: UpdateNotificationInput!) {
    updateNotification(id: $id, data: $data) {
      ...NotificationItem
    }
  }

  fragment EventPendingReview on Event {
    id
    name
    slug
    date
  }

  query GetEventsPendingReview {
    me {
      id
      eventsPendingReview {
        ...EventPendingReview
      }
    }
  }
`

export function Notifications() {
  const { me } = useMe()

  const { data } = useGetPendingConnectionsQuery({ skip: !!!me })
  const { data: pendingInvitations } = useGetPendingActInvitationsQuery({ skip: !!!me })
  const { data: notificationsData } = useGetNotificationsQuery()
  const { data: eventsPendingReviewData } = useGetEventsPendingReviewQuery({ skip: !!!me })

  const pendingRequests = data?.me?.pendingConnections
  const pendingPronouns = me?.pronounsMulti === null
  const pendingActInvitations = pendingInvitations?.pendingActInvitations
  const pendingEventReviews = eventsPendingReviewData?.me?.eventsPendingReview

  const notifications = notificationsData?.notifications

  if (!me) return null

  const hasNotifications =
    (pendingRequests && pendingRequests.length > 0) ||
    me.profileIncomplete ||
    (pendingActInvitations && pendingActInvitations.length > 0) ||
    (notifications && notifications.length > 0)
  return (
    <Popover closeOnBlur={false} placement="bottom-end">
      {({ onClose }) => (
        <>
          <DarkMode>
            <PopoverTrigger>
              <IconButton
                variant="ghost"
                borderRadius="full"
                aria-label="notificaties openen"
                icon={
                  <Box pos="relative">
                    <Box as={IoMdNotifications} boxSize="22px" />
                    {hasNotifications && (
                      <Box pos="absolute" top={0} right={0} bg="red.500" borderRadius="full" boxSize="10px" />
                    )}
                  </Box>
                }
              />
            </PopoverTrigger>
          </DarkMode>
          <PopoverContent borderRadius="sm" w={{ base: "100vw", md: "400px" }}>
            <PopoverArrow />
            <PopoverHeader fontWeight="bold">Notificaties</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody
              my={2}
              px={2}
              maxHeight={{ base: "80vh", md: "54vh" }}
              overflow="auto"
              css={{ "&::-webkit-scrollbar": { display: "none" } }}
            >
              <Stack spacing={12}>
                {(pendingRequests && pendingRequests.length !== 0) ||
                me.profileIncomplete ||
                pendingActInvitations?.length !== 0 ||
                pendingEventReviews?.length !== 0 ||
                (notifications && notifications.length !== 0) ? (
                  <>
                    {pendingPronouns && <PendingPronounsNotification userId={me.id} />}
                    {pendingRequests?.map((request) => (
                      <ConnectionRequest key={request.id} connectionRequest={request} />
                    ))}
                    {me.profileIncomplete && <IncompleteProfileNotification />}
                    {pendingActInvitations?.map((invitation) => (
                      <PendingInvitationsNotification invitation={invitation} key={invitation.id} />
                    ))}
                    {pendingEventReviews?.map((event) => (
                      <PendingEventReviewNotification event={event} key={event.id} />
                    ))}
                    {notifications?.map((notification) => (
                      <NotificationItem notification={notification} key={notification.id} onRead={onClose} />
                    ))}
                  </>
                ) : (
                  <Center h="80px">
                    <Text color="gray.500">Geen notificaties</Text>
                  </Center>
                )}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )

  interface ConnectionRequestProps {
    connectionRequest: PendingConnectionFragment
  }

  function ConnectionRequest({ connectionRequest }: ConnectionRequestProps) {
    const handler = useMutationHandler()
    const { me } = useMe()
    const [acceptConnect, { loading: acceptLoading }] = useAcceptConnectionMutation({
      refetchQueries: [
        { query: GetPendingConnectionsDocument },
        { query: GetUserDocument, variables: { id: connectionRequest.follower.id } },
        { query: GetUserDocument, variables: { id: me?.id || "" } },
      ],
    })
    const [rejectConnect, { loading: rejectLoading }] = useRejectConnectionMutation({
      refetchQueries: [
        { query: GetPendingConnectionsDocument },
        { query: GetUserDocument, variables: { id: connectionRequest.follower.id } },
        { query: GetUserDocument, variables: { id: me?.id || "" } },
      ],
    })

    const handleAcceptConnect = () => {
      return handler(() => acceptConnect({ variables: { followerId: connectionRequest.follower.id } }), {
        onSuccess: (_, toast) => toast({ description: "Verzoek geaccepteerd" }),
      })
    }
    const handleRejectConnect = () => {
      return handler(() => rejectConnect({ variables: { followerId: connectionRequest.follower.id } }), {
        onSuccess: (_, toast) => toast({ description: "Verzoek verwijderd" }),
      })
    }

    return (
      <LinkBox position="relative" overflow="hidden" p={2}>
        <Stack justifyContent="space-between" spacing={8}>
          <HStack>
            <NextLink passHref href={`/users/${connectionRequest.follower.id}`}>
              <LinkOverlay textDecoration="none">
                <Avatar
                  mr={2}
                  size="sm"
                  bg="gray.600"
                  icon={<FaUser color="gray.900" size={18} />}
                  src={
                    connectionRequest.follower.avatarUrl
                      ? transformImage(connectionRequest.follower.avatarUrl, "w_35,h_35,g_faces")
                      : undefined
                  }
                  name={connectionRequest.follower.fullName || "avatar"}
                />
              </LinkOverlay>
            </NextLink>
            <Box>
              <Text fontSize="sm">{connectionRequest.follower.fullName} wil graag verbinden </Text>
              <Text fontSize="xs" color="yellow.500">
                {dayjs(connectionRequest.createdAt).fromNow()}
              </Text>
            </Box>
          </HStack>
          <HStack justify="flex-end">
            <Button
              size="sm"
              isLoading={rejectLoading}
              isDisabled={rejectLoading || acceptLoading}
              variant="ghost"
              onClick={handleRejectConnect}
            >
              Ignore
            </Button>
            <Button
              size="sm"
              isLoading={acceptLoading}
              isDisabled={acceptLoading || rejectLoading}
              colorScheme="yellow"
              onClick={handleAcceptConnect}
            >
              Accept
            </Button>
          </HStack>
        </Stack>
      </LinkBox>
    )
  }
}

function IncompleteProfileNotification() {
  const { me } = useMe()

  if (!me) return null
  return (
    <LinkBox as="article" position="relative" overflow="hidden" p={2}>
      <LinkOverlay
        as={NextLink}
        textDecoration="none"
        passHref
        href={`/users/${me.customSlug ? me.customSlug : me.id}/edit`}
      >
        <Stack w="100%" position="relative" spacing={0}>
          <Box pr={8}>
            <Text fontSize="sm">Welkom bij Kunstbende. Vul meer over jezelf in in jouw profiel!</Text>
            <Text fontSize="xs" color="yellow.500">
              {dayjs(me.createdAt).fromNow()}
            </Text>
          </Box>
          <HStack justify="flex-end">
            <Box rounded="md" bg="yellow.500" py={1} px={3}>
              <Text color="black" fontWeight="bold" fontSize="sm">
                Profiel bewerken
              </Text>
            </Box>
          </HStack>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}

interface InvitationProps {
  invitation: PendingActFragment
}

function PendingInvitationsNotification({ invitation }: InvitationProps) {
  return (
    <LinkBox as="article" position="relative" overflow="hidden" p={2}>
      <LinkOverlay as={NextLink} textDecoration="none" passHref href={`/act/invitation/${invitation.id}`}>
        <Stack w="100%" position="relative" spacing={0}>
          <VStack align="flex-start">
            <Text fontSize="sm">Je bent uitgenodigd om deel te nemen aan een act.</Text>
            <Text fontSize="xs" color="yellow.500">
              {dayjs(invitation.createdAt).fromNow()}
            </Text>
          </VStack>
          <HStack justify="flex-end">
            <Box rounded="md" bg="yellow.500" py={1} px={3}>
              <Text color="black" fontWeight="bold" fontSize="sm">
                Bekijk
              </Text>
            </Box>
          </HStack>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}

interface EventReviewProps {
  event: EventPendingReviewFragment
}

function PendingEventReviewNotification({ event }: EventReviewProps) {
  return (
    <LinkBox as="article" position="relative" overflow="hidden" p={2}>
      <LinkOverlay as={NextLink} textDecoration="none" passHref href={`/te-doen/${event.slug}/review`}>
        <Stack justifyContent="space-between" w="100%">
          <VStack align="flex-start">
            <Text fontSize="sm">Laat ons alsjeblieft weten wat je van {event.name} vond</Text>
            <Text fontSize="xs" color="yellow.500">
              {dayjs(event.date).fromNow()}
            </Text>
          </VStack>
          <HStack justify="flex-end">
            <Box rounded="md" bg="yellow.500" py={1} px={3}>
              <Text color="black" fontWeight="bold" fontSize="sm">
                Beoordeling
              </Text>
            </Box>
          </HStack>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}

interface PronounProps {
  userId: string
}

function PendingPronounsNotification({ userId }: PronounProps) {
  return (
    <LinkBox as="article" position="relative" overflow="hidden" p={2}>
      <LinkOverlay as={NextLink} textDecoration="none" passHref href={`/users/${userId}/edit`}>
        <Stack w="100%" position="relative" spacing={0}>
          <VStack align="flex-start">
            <Text fontSize="sm">Wat zijn jouw voornaamwoorden?</Text>
            <Text fontSize="xs" color="yellow.500">
              {dayjs("2023-04-20").fromNow()}
            </Text>
          </VStack>
          <HStack justify="flex-end">
            <Box rounded="md" bg="yellow.500" py={1} px={3}>
              <Text color="black" fontWeight="bold" fontSize="sm">
                Update
              </Text>
            </Box>
          </HStack>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}
