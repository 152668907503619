import * as React from "react"
import { FaUser } from "react-icons/fa"
import { gql } from "@apollo/client"
import {
  Avatar,
  DarkMode,
  HStack,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react"
import NextLink from "next/link"

import { NullsOrder, QueryMode, SortOrder, useSearchUsersQuery } from "../lib/graphql"
import { UserNavSearch } from "./UserNavSearch"

const _ = gql`
  query SearchUsers(
    $take: Int
    $orderBy: [UserOrderByWithRelationInput!]
    $where: UserWhereInput
    $skip: Int
  ) {
    publicUsers(take: $take, orderBy: $orderBy, where: $where, skip: $skip) {
      items {
        ...UserItem
      }
      count
    }
  }
`

interface Props {
  closeMobileMenu?: () => void
}

export function UsersSearch({ closeMobileMenu }: Props) {
  const colorPlaceholder = useColorModeValue("grey", "white")
  const toggleContainer = React.useRef<HTMLDivElement>(null)
  const [search, setSearch] = React.useState<string>("")
  const [isSearching, setIsSearching] = React.useState<boolean>(false)

  const menuProps = useDisclosure()

  React.useEffect(() => {
    window.addEventListener("click", onClickOutsideHandler)

    return () => {
      window.removeEventListener("click", onClickOutsideHandler)
    }
  })

  const onClickOutsideHandler = (event: MouseEvent) => {
    if (
      menuProps.isOpen &&
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      menuProps.onClose()
    }
  }

  const { data } = useSearchUsersQuery({
    variables: {
      take: 10,
      orderBy: { firstName: { sort: SortOrder.Asc, nulls: NullsOrder.Last } },
      where: {
        OR: [
          { firstName: { contains: search, mode: QueryMode.Insensitive } },
          { lastName: { contains: search, mode: QueryMode.Insensitive } },
        ],
      },
    },
    skip: !!!search,
  })

  const searchedUsers = data?.publicUsers.items

  return (
    <Stack position="relative" ml="auto" mr={5} ref={toggleContainer}>
      <DarkMode>
        <UserNavSearch
          search={search}
          onChangeHandler={setSearch}
          placeholder="Zoek members"
          color="white"
          onFocus={() => {
            menuProps.onOpen()
            setIsSearching(true)
          }}
          _placeholder={{ color: colorPlaceholder }}
        />
      </DarkMode>
      {isSearching && (
        <Menu {...menuProps}>
          <MenuList marginTop={10} w="100%" minW="300px">
            {searchedUsers &&
              searchedUsers.map((user) => (
                <LinkBox key={user.id} w="100%" my={2} _hover={{ bgColor: "gray.800" }}>
                  <NextLink href={`/users/${user.customSlug ? user.customSlug : user.id}`} passHref>
                    <LinkOverlay onClick={closeMobileMenu} w="100%">
                      <HStack p={2}>
                        <Avatar
                          size="sm"
                          src={user.avatarUrl || undefined}
                          name={user.fullName || "avatar"}
                          bg="gray.600"
                          icon={<FaUser color="gray.900" size={18} />}
                        />
                        <Text>
                          {user.firstName} {user.lastName}
                        </Text>
                      </HStack>
                    </LinkOverlay>
                  </NextLink>
                </LinkBox>
              ))}
            <LinkBox w="100%" my={2} _hover={{ bgColor: "gray.800" }}>
              <NextLink href={`/users`} passHref>
                <LinkOverlay onClick={closeMobileMenu} w="100%">
                  <HStack p={2} w="100%" justify="center">
                    <Text fontWeight={700}>Bekijk alles</Text>
                  </HStack>
                </LinkOverlay>
              </NextLink>
            </LinkBox>
          </MenuList>
        </Menu>
      )}
    </Stack>
  )
}
